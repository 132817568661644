<template>
  <div class="list_body">
    <div :style="{ minHeight: '5vh' }">位置:资源列表</div>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="标题:">
          <div style="background-color: red; width: 20px; higth: 10px"></div>
          <a-input v-model="searchForm.title" placeholder="请输入" :style="{ display: 'inline' }" :allowClear="true" />
        </a-form-model-item>
        <a-form-model-item label="所属栏目:">
          <a-select defaultValue="请选择" style="width: 120px" v-model="searchForm.sectionId" :allowClear="true">
            <a-select-option :value="section.id" v-for="section in sections" :key="section.id"> {{ section.title }} </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <!-- 新增 -->
      <a-button type="primary" @click="showModal"> 新增 </a-button>
      <!-- 新增/修改 -->
      <a-modal title="新增/修改资源" :visible="visible" :confirm-loading="confirmLoading" @ok="addOk" @cancel="handleCancel" :mask-closable="false">
        <a-form-model layout="vertical" labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="标题:">
            <div style="background-color: red; width: 20px; higth: 10px"></div>
            <a-input v-model="resource.title" placeholder="请输入" :style="{ display: 'inline' }" :allowClear="true" />
          </a-form-model-item>
          <a-form-model-item label="所属栏目:">
            <a-select defaultValue="请选择" style="width: 220px" v-model="resource.sectionId" :allowClear="true">
              <a-select-option :value="section.id" v-for="section in sections" :key="section.id"> {{ section.title }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="资源格式:">
            <a-radio-group name="radioGroup" :default-value="ziyuan.accessory" v-model="resource.type">
              <a-radio :value="ziyuan.accessory"> 文档附件 </a-radio>
              <a-radio :value="ziyuan.link"> 网盘链接 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div v-if="resource.type == ziyuan.accessory">
            <a-form-model-item label="上传附件:">
              <upload-file :max-upload-num="3" v-model="fileInfos" @change="upload"></upload-file>
            </a-form-model-item>
          </div>
          <div v-if="resource.type == ziyuan.link">
            <a-form-model-item label="标题:">
              <a-input placeholder="请输入" style="width: 220px" v-model="resource.resourceLink.title" />
            </a-form-model-item>
            <a-form-model-item label="链接:">
              <a-input placeholder="请输入" style="width: 220px" v-model="resource.resourceLink.linkAddress" />
            </a-form-model-item>
            <a-form-model-item label="提取码:">
              <a-input placeholder="请输入" style="width: 220px" v-model="resource.resourceLink.fetchCode" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>

      <!-- 表格 -->
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :data-source="data"
        @change="handleTableChange"
        :pagination="pagination"
        :row-key="(record) => record.id"
      >
        <span slot="num" slot-scope="text, record, index"> {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}</span>
        <span slot="title" slot-scope="text">{{ text }}</span>
        <span slot="sectionName" slot-scope="text">{{ text }}</span>
        <span slot="created" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="showUpdate(record)">编辑</span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="del(record)"> 删除 </span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'num' }, //customRender值不能叫title
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '所属栏目',
    dataIndex: 'sectionName',
    key: 'sectionName',
    scopedSlots: { customRender: 'sectionName' },
  },
  {
    title: '创建时间',
    key: 'created',
    dataIndex: 'created',
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
const data = [];
export default {
  data() {
    return {
      ziyuan: { accessory: '附件', link: '链接' },
      previewVisible: false,
      previewImage: '',
      fileInfos: [],
      uploadList: [],
      loading: false,

      addOk: this.handleOk,
      visible: false,
      confirmLoading: false,
      resource: { title: '', type: '附件', accessories: [], resourceLink: {} },
      selectedRowKeys: [], // Check here to configure the default column
      sections: [],
      data, //表格数据，从服务端中获取
      columns, //表头
      searchForm: { title: '', sectionId: null },
      pagination: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取列表 */
    async loadList() {
      let result = await this.axios({
        method: 'post',
        url: `resource/search/${this.pagination.current}/${this.pagination.pageSize}`,
        data: this.searchForm,
      });
      let section = await this.axios({
        method: 'get',
        url: `section/sublist/2`,//2是固定的资源编号
      });
      this.sections = section.data.data;
      var datum = result.data.data ;
      this.pagination.total = result.data.total; //总数
      for (let d of datum) {
        for (let s of section.data.data)
          if (d.sectionId == s.id) {
            d['sectionName'] = s.title;
          }
      }
      this.data = datum;
    },
    /**确认查询 */
    searchSubmit(e) {
      this.loadList(); //重新读取列表
      e.preventDefault();
    },
    /**分页 */
    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination; //保存分页信息
      this.loadList(); //重新读取列表
    },
    /**拿到id */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    /**上传 */
    upload(data) {
      this.uploadList = data;
    },

    /**新增 */
    showModal() {
      this.visible = true;
      this.addOk = this.handleOk;
    },
    /**新增确定 */
    async handleOk(e) {
      this.confirmLoading = true;
      for (let f of this.uploadList) {
        this.resource.accessories.push(f);
      }
      try {
        let result = await this.axios({
          method: 'post',
          url: `resource/save`,
          data: this.resource,
        });
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.resource = { title: '', type: '附件', accessories: [], resourceLink: {} };
        this.fileInfos = [];
        this.ziyuan = { accessory: '附件', link: '链接' };
      }, 500);
    },
    /**新增取消 */
    handleCancel(e) {
      this.resource = { title: '', type: '附件', accessories: [], resourceLink: {} };
      this.ziyuan = { accessory: '附件', link: '链接' };
      this.fileInfos = [];
      this.defaultFileList = [];
      this.visible = false;
    },
    /**点击修改 */
    async showUpdate(obj) {
      this.visible = true;
      let result = await this.axios.get(`resource/find/${obj.id}?type=${obj.type}`);
      //深拷贝
      this.resource = JSON.parse(JSON.stringify(result.data.data));
      if (this.resource.type == '附件') {
        for (let f of this.resource.accessories) {
          this.fileInfos.push({
            uid: f.id,
            name: f.fileName,
            status: 'done',
            url: f.path,
            // thumbUrl: this.cdn + fl,
          });
        }
      }
      this.addOk = this.update;
    },
    /**确认修改 */
    async update() {
      this.confirmLoading = true;
      this.resource.accessories = [];
      for (let u of this.uploadList) {
        this.resource.accessories.push({ fileName: u.fileName, path: u.path });
      }
      try {
        let result = await this.axios({
          method: 'post',
          url: `resource/update`,
          data: this.resource,
        });
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.resource = { title: '', type: '附件', accessories: [], resourceLink: {} };
        this.ziyuan = { accessory: '附件', link: '链接' };
        this.defaultFileList = [];
        this.fileInfos = [];
      }, 500);
    },

    /**删除 */
    del(obj) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let result = await that.axios.delete(`resource/delete/${obj.id}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.ant-table-title {
  display: none;
}
</style>
